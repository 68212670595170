import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const ArticleCard = ({ article }) => (
  <div
    key={article.title}
    className="flex flex-col rounded-xl shadow-lg overflow-hidden"
  >
    <div className="flex-shrink-0">
      <GatsbyImage
        image={article.image.childImageSharp.gatsbyImageData}
        alt={article.title}
        className={`h-48 w-full object-cover`}
      />
    </div>
    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
      <div className="flex-1">
        <Link to={article.path} className="block my-3">
          <h3 className="text-xl font-semibold text-primary">
            {article.title}
          </h3>
          <p className="mt-3 text-base text-gray-500">{article.subtitle}</p>
        </Link>
      </div>
    </div>
  </div>
)

export default ArticleCard
