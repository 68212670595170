import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ArticleCard from '../components/ArticleCard'
import Button from '../components/Button'
import SnippetCard from '../components/SnippetCard'

const Index = (data) => {
  const articles = data.data.articleSet.edges.map((edge) => (
    <ArticleCard key={edge.node.id} article={edge.node.frontmatter} />
  ))
  const snippets = data.data.snippetSet.edges.map((edge) => (
    <SnippetCard key={edge.node.id} snippet={edge.node.frontmatter} />
  ))

  return (
    <Layout>
      <Seo
        title="Home"
        description="Web Development blog, focusing on PHP and other web technologies"
      />

      <h2 className="font-bold text-2xl text-center my-10">Latest Articles</h2>

      <div className="container mb-14">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">{articles}</div>
      </div>

      <h2 className="font-bold text-2xl text-center my-10">Latest Snippets</h2>

      <div className="container mb-14">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">{snippets}</div>
      </div>

      <div id="contact" className="container my-18">
        <div className="prose lg:prose-lg max-w-none">
          <h2 className="font-bold">Get in touch</h2>
          <p>
            Feel free to send me a message using the form below. I respond to
            all comments and will get back to you as soon as possible!
          </p>
        </div>

        <form
          name="Contact Form"
          action="/thanks"
          method="post"
          data-netlify="true"
          className="mt-2 mb-10"
        >
          <div className="flex flex-wrap -mx-3 my-6">
            <div className="w-full md:w-1/2 px-3 mb-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border
                        border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none"
                id="name"
                type="text"
                name="name"
                placeholder="Your Name"
                required
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border
                        border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none"
                id="email"
                type="email"
                name="email"
                placeholder="Your Email"
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3 mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                name="message"
                id="message"
                placeholder="Your Message"
                rows="6"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                required
              ></textarea>
            </div>
            <input type="hidden" name="form-name" value="Contact Form" />
            <Button text="Send Message" type="submit" margin="true" />
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Index

export const query = graphql`
  {
    articleSet: allMarkdownRemark(
      limit: 4
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { path: { regex: "/article/" }, status: { eq: "live" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            image {
              childImageSharp {
                gatsbyImageData(width: 600, layout: CONSTRAINED)
              }
            }
            title
            subtitle
            date(formatString: "MMMM YYYY")
          }
        }
      }
    }
    snippetSet: allMarkdownRemark(
      limit: 4
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { path: { regex: "/snippet/" }, status: { eq: "live" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            subtitle
            date(formatString: "MMMM YYYY")
          }
        }
      }
    }
  }
`
