import React from 'react'
import { Link } from 'gatsby'

class Button extends React.Component {
  render() {
    const { text, link, linkType, type, margin } = this.props
    if (link && linkType === 'internal') {
      return (
        <Link to={link}>
          <button
            type={type ? type : ''}
            className={`${
              margin ? `mx-3` : ``
            } no-underline inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-primary hover:opacity-90 focus:outline-none">`}
          >
            {text}
          </button>
        </Link>
      )
    } else if (link && linkType === 'external') {
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <button
            className={`${
              margin ? `mx-3` : ``
            }  no-underline inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-primary hover:opacity-90 focus:outline-none">`}
          >
            {text}
          </button>
        </a>
      )
    } else {
      return (
        <button
          type={type ? type : ''}
          className={`${
            margin ? `mx-3` : ``
          } no-underline inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-primary hover:opacity-90 focus:outline-none">`}
        >
          {text}
        </button>
      )
    }
  }
}

export default Button
